<template>
  <div class="resume mx-auto min-vh-100">
    <div id="flex-content">
      <HeaderVodafone />
      <section class="">
        <h1 class="display-5 mx-auto my-3 my-md-5 text-center">
          Resultado Procesado
        </h1>
      </section>

      <div class="mx-auto row my-3 my-md-5">
        <div id="mainJsoneditor" style="width: 100%; height: 1600px"></div>
      </div>
    </div>
    <FooterVodafone />
  </div>
</template>

<script>
import HeaderVodafone from "@/components/Widgets/vodafone/Header.vue";
import FooterVodafone from "@/components/Widgets/vodafone/Footer.vue";

import mockScoring from "@/api/mocks/scoring.json";
import JSONEditor from "jsoneditor";

import { mapActions } from "vuex";

export default {
  name: "Resultado",
  components: {
    HeaderVodafone,
    FooterVodafone,
  },
  data() {
    return {
      jsonMock: mockScoring,
    };
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Vodafone Scoring Resultado",
    });
    this.startScroll();
  },
  mounted() {
    let containerMainJsonEditor = document.getElementById("mainJsoneditor");
    let optionsMainJsonEditor = {
      mode: "tree",
      modes: ["code", "text", "tree"], // allowed modes
      onError: function (err) {
        alert(err.toString());
      },
      onEditable: function (node) {
        if (!node.path) {
          // In modes code and text, node is empty: no path, field, or value
          // returning false makes the text area read-only
          return false;
        }
        return false;
      },
    };
    this.mainJsonEditor = new JSONEditor(
      containerMainJsonEditor,
      optionsMainJsonEditor
    );
    this.mainJsonEditor.set(this.jsonMock);
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
      startScroll: "startScroll",
    }),
  },
};
</script>

<style scoped>
.resume {
  max-width: 1900px;
}

#flex-content {
  flex: 1 0 auto;
}

h1 {
  color: #ed3022;
}
</style>
