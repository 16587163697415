<template>
  <div class="scoring mx-auto min-vh-100">
    <div id="flex-content">
      <ScoringHeader />
      <div class="mx-auto row my-3 my-md-5">
        <section class="col-12 col-md-8">
          <!-- TEXTO INICIAL -->
          <div>
            <h3 class="text-start ms-5 my-5">
              Scoring de las cuentas del cliente
            </h3>
            <h4 class="text-start ms-5 my-5">
              1️⃣ El cliente selecciona su banco y se realiza la lectura de sus
              cuentas
            </h4>
            <h4 class="text-start ms-5 my-5">
              2️⃣ Se enriquece la lectura y se realiza el scoring
            </h4>
            <h4 class="text-start ms-5 my-5">
              3️⃣ Se envía la respuesta al callback del cliente
            </h4>
          </div>
        </section>
      </div>
    </div>
    <ScoringFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import ScoringHeader from "@/components/Widgets/scoring/Header.vue";
import ScoringFooter from "@/components/Widgets/scoring/Footer.vue";

import { mapActions } from "vuex";

export default {
  name: "Scoring",
  components: {
    ScoringHeader,
    ScoringFooter,
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Scoring",
    });
    this.startScroll();
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
      startScroll: "startScroll",
    }),
  },
};
</script>

<style scoped>
.scoring {
  display: flex;
  flex-direction: column;
}

#flex-content {
  flex: 1 0 auto;
}
</style>

<style>
.jsoneditor {
  border: thin solid #fff;
}

.jsoneditor-menu {
  color: #fffbca;
  background-color: #fffbca;
  border-bottom: 1px solid #f7e425;
}

.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
  color: #fffbca;
  background-color: #000;
  opacity: 0.8;
  font-family: arial, sans-serif;
}

.jsoneditor-menu > button:hover,
.jsoneditor-menu > .jsoneditor-modes > button:hover {
  color: #fffbca;
  background-color: #000;
  opacity: 0.8;
  font-family: arial, sans-serif;
}
</style>
