<template>
  <footer class="">
    <div class="text-center py-3">
      <p class="">Powered by:</p>
      <img
        src="https://www.arcopay.io/assets/img/arcopay/logos/arcopay/logo_arcopay_web.min.svg"
        alt=""
        class=""
        style="height: 3rem"
      />
    </div>
  </footer>
</template>

<script>
export default {
  name: "VodafoneFooter",
  props: {},
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  flex-shrink: 0;
  background-color: #fff3f2 !important;
}
</style>
