<template>
  <b-card no-body class="cardParametro">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button
        block
        v-b-toggle="'accordion_' + parametro.titulo"
        variant="light"
        class="w-100 text-start botonParametro"
        >{{ parametro.titulo }}</b-button
      >
    </b-card-header>
    <b-collapse
      :id="'accordion_' + parametro.titulo"
      accordion="accordionParametros"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <span v-if="parametro.obligatorio" class="bold d-block"
            >Obligatorio</span
          >
          {{ parametro.descripcion }}</b-card-text
        >
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: "Parametro",
  props: {
    parametro: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cardParametro {
  margin-top: 2.68rem;
}
.botonParametro {
  padding: 0.1rem 0.75rem;
}
.bold {
  font-weight: 800;
}
</style>
