<template>
  <div class="pagosCasos">
    <div class="d-flex flex-column justify-content-evenly top-ab">
      <ZonaLinks
        title="Casos de uso Generales de Pagos"
        :showTitle="true"
        :links="linksGenerales"
      />
      <ZonaLinks
        title="Casos de uso de Clientes de Pagos"
        :showTitle="true"
        :links="linksClientes"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ZonaLinks from "@/components/Home/ZonaLinks.vue";
import linksPagosCasos from "@/api/links/pagosCasos.json";

import { mapActions } from "vuex";

export default {
  name: "PagosCasos",
  components: {
    ZonaLinks,
  },
  data() {
    return {
      linksGenerales: linksPagosCasos.linksGenerales,
      linksClientes: linksPagosCasos.linksClientes,
    };
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Casos de uso de Pagos",
    });
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-ab {
  padding: 2rem;
}
</style>
