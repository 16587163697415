var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bodyView mx-auto min-vh-100"},[_c('section',{staticClass:"container mt-5",attrs:{"id":"switchers"}},[_c('div',{staticClass:"row"},_vm._l((_vm.switchesList),function(formSwitch,index){return _c('SwitchInput',{key:index,attrs:{"formSwitch":formSwitch},on:{"click":function($event){return _vm.cargaParametros()}}})}),1)]),_c('hr',{staticClass:"dropdown-divider my-5"}),_c('div',{staticClass:"mx-auto row my-3 my-md-5"},[_c('section',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"col"},[_c('form',{attrs:{"method":"POST","id":"form-add-client"}},[_c('div',{staticClass:"form-group col-12 col-md-11 mx-auto",attrs:{"id":"inputs"}},[_vm._l((_vm.inputsList),function(inputParametro,index){return _c('Input',{key:index,attrs:{"inputParametro":inputParametro,"mostrarTodosLosParametros":_vm.switches.mostrarTodosLosParametros}})}),(!_vm.switches.widgetIsLoading)?_c('button',{staticClass:"btn btn-primary col mt-4 w-100",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.cargarWidget()}}},[_vm._v(" Cargar Widget ")]):_vm._e(),(_vm.switches.widgetIsLoading)?_c('button',{staticClass:"btn btn-primary col mt-4 w-100",attrs:{"type":"submit","disabled":true}},[_c('div',{staticClass:"spinner-border text-dark",attrs:{"role":"status"}})]):_vm._e()],2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.switches.mostrarJSONCallback),expression:"switches.mostrarJSONCallback"}],staticClass:"col"},[_c('hr',{staticClass:"dropdown-divider my-5"}),_c('h3',{staticClass:"mb-3"},[_vm._v("JSON enviado a callback")]),_c('div',{staticStyle:{"width":"90%","height":"250px"},attrs:{"id":"callbackJsonEditor"}})])]),_c('hr',{staticClass:"dropdown-divider my-5 d-md-none"}),_c('section',{staticClass:"col-12 col-md-8"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.switches.widgetIsLoading &&
          !_vm.switches.mostrarWidget &&
          !_vm.switches.mostrarDocumentacion &&
          !_vm.switches.mostrarJSON
        ),expression:"\n          !switches.widgetIsLoading &&\n          !switches.mostrarWidget &&\n          !switches.mostrarDocumentacion &&\n          !switches.mostrarJSON\n        "}]},[_c('h3',{staticClass:"my-5"},[_vm._v("👈 Indica los parámetros de la agregación")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.switches.mostrarDocumentacion),expression:"switches.mostrarDocumentacion"}],staticClass:"col-12"},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist","id":"accordionParametros"}},_vm._l((_vm.parametrosList),function(parametro,index){return _c('parametro',{key:index,attrs:{"parametro":parametro}})}),1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.switches.mostrarJSON),expression:"switches.mostrarJSON"}],staticClass:"col-12"},[_c('div',{staticStyle:{"width":"100%","height":"1600px"},attrs:{"id":"mainJsoneditor"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.switches.mostrarWidget && _vm.switches.widgetIsLoading),expression:"!switches.mostrarWidget && switches.widgetIsLoading"}]},[_vm._m(0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.switches.mostrarWidget &&
          !_vm.switches.mostrarDocumentacion &&
          !_vm.switches.widgetIsLoading &&
          !_vm.switches.mostrarJSON
        ),expression:"\n          switches.mostrarWidget &&\n          !switches.mostrarDocumentacion &&\n          !switches.widgetIsLoading &&\n          !switches.mostrarJSON\n        "}],attrs:{"id":"arcopayContainer"}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"my-5"},[_c('div',{staticClass:"spinner-border text-dark",attrs:{"role":"status"}}),_vm._v(" Cargando el widget ")])}]

export { render, staticRenderFns }