<template>
  <div class="bodyView mx-auto min-vh-100">
    <!-- SWITCHERS -->
    <section id="switchers" class="container mt-5">
      <div class="row">
        <SwitchInput
          v-for="(formSwitch, index) in switchesList"
          :key="index"
          :formSwitch="formSwitch"
          v-on:click="cargaParametros()"
        />
      </div>
    </section>

    <hr class="dropdown-divider my-5" />

    <div class="mx-auto row my-3 my-md-5">
      <section class="col-12 col-md-4">
        <!-- INPUTS -->
        <div class="col">
          <form method="POST" id="form-add-client">
            <div id="inputs" class="form-group col-12 col-md-11 mx-auto">
              <Input
                v-for="(inputParametro, index) in inputsList"
                :key="index"
                :inputParametro="inputParametro"
                :mostrarTodosLosParametros="switches.mostrarTodosLosParametros"
              />

              <button
                v-if="!switches.widgetIsLoading"
                class="btn btn-primary col mt-4 w-100"
                type="submit"
                v-on:click.prevent="cargarWidget()"
              >
                Cargar Widget
              </button>

              <button
                v-if="switches.widgetIsLoading"
                class="btn btn-primary col mt-4 w-100"
                type="submit"
                :disabled="true"
              >
                <div class="spinner-border text-dark" role="status"></div>
              </button>
            </div>
          </form>
        </div>

        <!-- JSON ENVIADO AL CALLBACK -->
        <div v-show="switches.mostrarJSONCallback" class="col">
          <hr class="dropdown-divider my-5" />

          <h3 class="mb-3">JSON enviado a callback</h3>
          <div id="callbackJsonEditor" style="width: 90%; height: 250px"></div>
        </div>
      </section>

      <hr class="dropdown-divider my-5 d-md-none" />

      <section class="col-12 col-md-8">
        <!-- TEXTO TEMPORAL INICIAL -->
        <div
          v-show="
            !switches.widgetIsLoading &&
            !switches.mostrarWidget &&
            !switches.mostrarTiposDePago &&
            !switches.mostrarDocumentacion &&
            !switches.mostrarJSON
          "
        >
          <h3 class="my-5">👈 Indica los parámetros del pago</h3>
        </div>

        <!-- TIPOS DE PAGO POSIBLES -->
        <div v-show="switches.mostrarTiposDePago" class="col-12">
          <tipo-de-pago
            v-for="(tipo, index) in tiposList"
            :key="index"
            :tipo="tipo"
          ></tipo-de-pago>
        </div>

        <!-- DOCUMENTACIÓN DE PARÁMETROS -->
        <div v-show="switches.mostrarDocumentacion" class="col-12">
          <div class="accordion" role="tablist" id="accordionParametros">
            <parametro
              v-for="(parametro, index) in parametrosList"
              :key="index"
              :parametro="parametro"
            ></parametro>
          </div>
        </div>

        <!-- JSON EDITOR -->
        <div v-show="switches.mostrarJSON" class="col-12">
          <div id="mainJsoneditor" style="width: 100%; height: 1600px"></div>
        </div>

        <!-- TEXTO TEMPORAL CARGA DEL WIDGET -->
        <div v-show="!switches.mostrarWidget && switches.widgetIsLoading">
          <h3 class="my-5">
            <div class="spinner-border text-dark" role="status"></div>
            Cargando el widget
          </h3>
        </div>

        <!-- WIDGET -->
        <div
          v-show="
            switches.mostrarWidget &&
            !switches.mostrarTiposDePago &&
            !switches.mostrarDocumentacion &&
            !switches.widgetIsLoading &&
            !switches.mostrarJSON
          "
          id="arcopayContainer"
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SwitchInput from "@/components/Widgets/Switch.vue";
import Input from "@/components/Widgets/Input.vue";
import Parametro from "@/components/Widgets/Parametro.vue";
import TipoDePago from "@/components/Widgets/psd2/TipoDePago.vue";

import switchesList from "@/api/switches/pagosPSD2.json";
import inputsList from "@/api/inputs/pagosPSD2.json";
import parametrosList from "@/api/parametros/pagosPSD2.json";
import tiposList from "@/api/tipos/pagosPSD2.json";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import JSONEditor from "jsoneditor";
import $ from "jquery";

export default {
  name: "PagosPSD2",
  components: {
    Parametro,
    SwitchInput,
    Input,
    TipoDePago,
  },
  data() {
    return {
      switchesList: switchesList,
      inputsList: inputsList,
      parametrosList: parametrosList,
      tiposList: tiposList,
      eventResponse: {},
      widgetResponseToCallback: {},
      mainJsonEditor: "",
      callbackJsonEditor: "",
    };
  },
  computed: {
    ...mapGetters({
      parametros: "getParametrosPSD2",
      switches: "getSwitches",
    }),
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Pagos PSD2",
    });
  },
  destroyed() {
    this.resetParametrosPSD2();
    this.switches.mostrarJSONCallback = false;
  },
  mounted() {
    this.resetParametrosPSD2();
    this.removeParametroPSD2("banksShownPSD2");
    this.removeParametroPSD2("banksShownV3");
    this.removeParametroPSD2("dailyFrecuency");
    this.removeParametroPSD2("validUntil");

    this.setParametroPSD2({ target: "action", value: "payment" });

    // JSON EDITOR PRINCIPAL, PARÁMETROS CONFIGURADOS
    let containerMainJsonEditor = document.getElementById("mainJsoneditor");
    let optionsMainJsonEditor = {
      mode: "code",
      modes: ["code", "text", "tree"], // allowed modes
      onError: function (err) {
        alert(err.toString());
      },
      onEditable: function (node) {
        if (!node.path) {
          return false;
        }
        return false;
      },
    };
    this.mainJsonEditor = new JSONEditor(
      containerMainJsonEditor,
      optionsMainJsonEditor
    );
    this.mainJsonEditor.set(this.parametros);

    // JSON EDITOR SECUNDARIO, RESPUESTA EN CALLBACK
    let containerCallbackJsonEditor =
      document.getElementById("callbackJsonEditor");

    let optionsCallbackJsonEditor = {
      mode: "code",
      modes: ["code", "text", "tree"], // allowed modes
      onError: function (err) {
        alert(err.toString());
      },
      onEditable: function (node) {
        if (!node.path) {
          return false;
        }
        return false;
      },
    };
    this.callbackJsonEditor = new JSONEditor(
      containerCallbackJsonEditor,
      optionsCallbackJsonEditor
    );
    this.callbackJsonEditor.set({});
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
      resetParametrosPSD2: "resetParametrosPSD2",
      setParametroPSD2: "setParametroPSD2",
      removeParametroPSD2: "removeParametroPSD2",
      setSwitch: "setSwitch",
      startScroll: "startScroll",
    }),
    cargarWidget: function () {
      this.setSwitch({ target: "widgetIsLoading", value: true });
      this.setSwitch({ target: "mostrarDocumentacion", value: false });
      this.setSwitch({ target: "mostrarJSON", value: false });
      this.setSwitch({ target: "mostrarJSONCallback", value: false });

      let parameters = Object.assign({
        id: this.parametros.id,
        action: this.parametros.action,
        countryCode: this.parametros.countryCode,
        banksShown: this.parametros.banksShown,
        defaultBank: this.parametros.defaultBank,
        autoStart: this.parametros.autoStart,
        defaultLanguage: this.parametros.defaultLanguage,
        showSandbox: this.parametros.showSandbox,
        showSplashScreen: this.parametros.showSplashScreen,
        showCheckTerms: this.parametros.showCheckTerms,
        URLredirectAfterOK: this.parametros.URLredirectAfterOK,
        URLredirectAfterKO: this.parametros.URLredirectAfterKO,
        paymentType: this.parametros.paymentType,
        amount: this.parametros.amount,
        currency: this.parametros.currency,
        sourceIBAN: this.parametros.sourceIBAN,
        sourceCreditorName: this.parametros.sourceCreditorName,
        destinationIBAN: this.parametros.destinationIBAN,
        destinationCreditorName: this.parametros.destinationCreditorName,
        paymentDescription: this.parametros.paymentDescription,
        firstQuotaDay: this.parametros.firstQuotaDay,
        frequency: this.parametros.frequency,
        numberOfQuotas: this.parametros.numberOfQuotas,
        getCallbackResult: 1,
      });

      let html = `<iframe title="Arcopay Widget PSD2" id="iframeArcopay" src="https://www.afterbanks.com/appmain/PSD2ExternalForm/?action=${this.parametros.action}&showSplashScreen=${this.parametros.showSplashScreen}&showCheckTerms=${this.parametros.showCheckTerms}&showSandbox=${this.parametros.showSandbox}" frameborder="no" framespacing="0" border="0" width="100%" height="100%"></iframe>`;

      let arcopayContainer = document.getElementById("arcopayContainer");
      $(arcopayContainer).empty();
      $(arcopayContainer).append(html);

      let self = this;
      let arcopayIframe = document.getElementById("iframeArcopay");
      arcopayIframe.addEventListener("load", function () {
        arcopayIframe.contentWindow.postMessage(parameters, "*");
        self.setSwitch({ target: "widgetIsLoading", value: false });
        self.setSwitch({ target: "mostrarWidget", value: true });
      });

      window.addEventListener("message", this.receiveFromFrame, false);
    },
    cargaParametros: function () {
      this.mainJsonEditor.set(this.parametros);
    },
    receiveFromFrame: function (evento) {
      if (
        evento.data != "" &&
        evento.data.code != undefined &&
        evento.data.message != undefined
      ) {
        this.eventResponse = evento.data;
        let codigo = evento.data.code;
        let mensaje = evento.data.message;
        let arcopayContainer = document.getElementById("arcopayContainer");
        let json = Object.prototype.hasOwnProperty.call(evento.data, "json")
          ? evento.data.json
          : JSON.parse("{}");

        console.warn(`${codigo} - ${mensaje}`);

        switch (codigo) {
          case 200:
            this.widgetResponseToCallback = json;
            this.callbackJsonEditor.set(this.widgetResponseToCallback);
            this.switches.mostrarJSONCallback = true;
            break;
          case 9999: // Cambio en el tamaño del widget
            arcopayContainer.style.height = mensaje + "px";
            this.startScroll();
            document.getElementById("iframeArcopay").contentWindow.postMessage(
              {
                action: "changeIframeHeight",
              },
              "*"
            );
            break;
          case 99999999: // Cambio en el tamaño del iframe
            break;

          default:
            // document.getElementById('frameResponse').innerHTML = `${codigo} - ${mensaje}`;
            break;
        }
      }
    },
  },
  watch: {
    parametros: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler() {
        this.mainJsonEditor.set(this.parametros);
      },
    },
  },
};
</script>

<style scoped>
.bodyView {
  max-width: 1900px;
}

h1 {
  color: #000;
}

.btn-primary {
  color: #000;
  background-color: #fffbca !important;
  border-color: #f7e425;
}

.bg-light {
  color: #000 !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fffbca;
  box-shadow: inset 0 -1px 0 #fffbca;
}

.accordion-button:focus {
  border-color: #fffbca;
  box-shadow: 0 0 0 0.25rem #f7e425;
}
</style>

<style>
.jsoneditor {
  border: thin solid #fff;
}

.jsoneditor-menu {
  color: #fffbca;
  background-color: #fffbca;
  border-bottom: 1px solid #f7e425;
}

.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
  color: #fffbca;
  background-color: #000;
  opacity: 0.8;
  font-family: arial, sans-serif;
}

.jsoneditor-menu > button:hover,
.jsoneditor-menu > .jsoneditor-modes > button:hover {
  color: #fffbca;
  background-color: #000;
  opacity: 0.8;
  font-family: arial, sans-serif;
}
</style>
