<template>
  <div
    class="col mt-3 text-start"
    v-if="mostrarTodosLosParametros || inputParametro.alwaysShown"
  >
    <label class="floating-label" :for="inputParametro.id"
      ><h6>
        {{ inputParametro.titulo }}
        <span class="badge bg-light" v-if="inputParametro.obligatorio"
          >Required</span
        >
        <span class="badge bg-light" v-if="inputParametro.readParameterOnly"
          >READ Parameter</span
        >
        <span class="badge bg-light" v-if="inputParametro.paymentParameterOnly"
          >PAYMENT Parameter</span
        >
      </h6></label
    >
    <input
      v-if="inputParametro.inputType == 'text'"
      type="text"
      class="form-control"
      :name="inputParametro.id"
      :id="inputParametro.id"
      v-model="input"
      :placeholder="inputParametro.placeholder"
      :required="inputParametro.obligatorio"
      :readonly="inputParametro.readonly"
    />
    <input
      v-if="inputParametro.inputType == 'number'"
      type="number"
      class="form-control"
      :name="inputParametro.id"
      :id="inputParametro.id"
      v-model="input"
      :placeholder="inputParametro.placeholder"
      :required="inputParametro.obligatorio"
      :readonly="inputParametro.readonly"
      :step="inputParametro.numberStep"
    />
    <select
      v-if="inputParametro.inputType == 'select'"
      class="form-control"
      :name="inputParametro.id"
      :id="inputParametro.id"
      v-model="input"
      :placeholder="inputParametro.placeholder"
      :required="inputParametro.obligatorio"
      :readonly="inputParametro.readonly"
    >
      <option
        v-for="(option, index) in this.inputParametro.selectOptions"
        :key="index"
        :value="index"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Parametro",
  props: {
    inputParametro: Object,
    mostrarTodosLosParametros: Boolean,
  },
  computed: {
    input: {
      get() {
        let getter = this.$store.getters[`getParametrosPSD2`];
        return getter[this.inputParametro.id];
      },
      set(value) {
        return this.setParametroPSD2({
          target: this.inputParametro.id,
          value: value,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      setParametroPSD2: "setParametroPSD2",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg-light {
  color: #000 !important;
}
.badge {
  padding: 0.15em 0.65em !important;
  font-size: 0.65em !important;
}
</style>
