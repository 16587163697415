<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="'accordion_' + tipo.titulo">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#collapse_' + tipo.titulo"
        aria-expanded="true"
        :aria-controls="'collapse_' + tipo.titulo"
      >
        {{ tipo.titulo }}
      </button>
    </h2>
    <div
      :id="'collapse_' + tipo.titulo"
      class="accordion-collapse collapse collapsed show"
      :aria-labelledby="'accordion_' + tipo.titulo"
      data-bs-parent="#accordionParametros"
    >
      <div class="accordion-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">ID</li>
          <li class="list-group-item">AMOUNT</li>
          <li v-if="tipo.sourceIBAN" class="list-group-item">SOURCE IBAN</li>
          <li v-if="tipo.sourceCreditorName" class="list-group-item">
            SOURCE CREDITOR NAME
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TipoDePago",
  props: {
    tipo: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #fff6cf;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
</style>
