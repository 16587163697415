<template>
  <div class="col">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        :id="formSwitch.id"
        v-model="inputSwitch"
      />
      <label class="form-check-label" :for="formSwitch.id">{{
        formSwitch.texto
      }}</label>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FormSwitch",
  props: {
    formSwitch: Object,
  },
  computed: {
    inputSwitch: {
      get() {
        let getter = this.$store.getters[`getSwitches`];
        return getter[this.formSwitch.id];
      },
      set(value) {
        return this.setSwitch({
          target: this.formSwitch.id,
          value: value,
        });
      },
    },
  },
  methods: {
    ...mapActions({
      setSwitch: "setSwitch",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
