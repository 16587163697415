<template>
  <div class="request mx-auto min-vh-100">
    <div id="flex-content">
      <HeaderVodafone />
      <section class="">
        <h1 class="display-5 mx-auto my-3 my-md-5 text-center">
          Lectura de cuentas
        </h1>
      </section>
      <div id="arcopayResponse" class="mw-100 mx-3">
        <span id="arcopayResponse-icono"></span>
        <span id="arcopayResponse-texto"></span>
      </div>
      <div id="arcopayContainer" style="max-width: 70%" class="mx-auto"></div>
    </div>
    <FooterVodafone />
  </div>
</template>

<script>
import HeaderVodafone from "@/components/Widgets/vodafone/Header.vue";
import FooterVodafone from "@/components/Widgets/vodafone/Footer.vue";

import $ from "jquery";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "ScoringLectura",
  components: {
    HeaderVodafone,
    FooterVodafone,
  },
  data() {
    return {
      eventResponse: {},
      styles: {
        okClass: "bg-primary",
        koClass: "bg-danger",
      },
      formValidation: {
        addClientError: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      parametros: "getParametrosPSD2",
    }),
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Vodafone Scoring Lectura",
    });
    this.startScroll();
  },
  destroyed() {
    this.resetParametrosPSD2();
    window.removeEventListener("message", this.receiveFromFrame, false);
  },
  mounted() {
    this.resetParametrosPSD2();
    this.removeParametroPSD2("banksShown");
    this.removeParametroPSD2("paymentType");
    this.removeParametroPSD2("amount");
    this.removeParametroPSD2("currency");
    this.removeParametroPSD2("sourceIBAN");
    this.removeParametroPSD2("sourceCreditorName");
    this.removeParametroPSD2("destinationIBAN");
    this.removeParametroPSD2("destinationCreditorName");
    this.removeParametroPSD2("paymentDescription");
    this.removeParametroPSD2("firstQuotaDay");
    this.removeParametroPSD2("frequency");
    this.removeParametroPSD2("numberOfQuotas");
    this.removeParametroPSD2("banksShownPSD2");

    this.setParametroPSD2({ target: "id", value: "609a40aee37274a164048532" });
    this.setParametroPSD2({ target: "action", value: "read" });
    this.setParametroPSD2({
      target: "banksShownV3",
      value: "ALL",
    });

    let parameters = Object.assign({
      id: this.parametros.id,
      action: this.parametros.action,
      countryCode: this.parametros.countryCode,
      banksShownV3: this.parametros.banksShownV3,
      defaultBank: this.parametros.defaultBank,
      showCheckTerms: this.parametros.showCheckTerms,
      showSplashScreen: this.parametros.showSplashScreen,
      showSandbox: this.parametros.showSandbox,
      sendInternalEvents: 1,
      client: "vodafone",
    });

    let html = `<iframe title="Arcopay Widget NO PSD2" id="iframeArcopay" src="https://www.afterbanks.com/appmain/PSD2ExternalForm/?action=${this.parametros.action}&showSplashScreen=${this.parametros.showSplashScreen}&showCheckTerms=${this.parametros.showCheckTerms}&showSandbox=${this.parametros.showSandbox}&client=${parameters.client}" frameborder="no" framespacing="0" border="0" width="100%" height="100%"></iframe>`;

    let arcopayContainer = document.getElementById("arcopayContainer");
    $(arcopayContainer).empty();
    $(arcopayContainer).append(html);

    let arcopayIframe = document.getElementById("iframeArcopay");
    arcopayIframe.addEventListener("load", function () {
      arcopayIframe.contentWindow.postMessage(parameters, "*");
    });

    window.addEventListener("message", this.receiveFromFrame, false);
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
      startScroll: "startScroll",
      resetParametrosPSD2: "resetParametrosPSD2",
      setParametroPSD2: "setParametroPSD2",
      removeParametroPSD2: "removeParametroPSD2",
    }),
    receiveFromFrame: function (evento) {
      if (
        evento.data != "" &&
        evento.data.code != undefined &&
        evento.data.message != undefined
      ) {
        this.eventResponse = evento.data;
        let codigo = evento.data.code;
        let mensaje = evento.data.message;

        let widgetContainer = document.getElementById("arcopayContainer");
        let responseContainer = document.getElementById("arcopayResponse");
        let responseContainerIcono = document.getElementById(
          "arcopayResponse-icono"
        );
        let responseContainerTexto = document.getElementById(
          "arcopayResponse-texto"
        );

        switch (codigo) {
          case 200:
          case 400:
          case 401:
          case 402:
          case 403:
            $(responseContainer).addClass("p-3 mb-4");
            $(responseContainerIcono).empty();
            $(responseContainerTexto).empty();
            break;
          default:
            break;
        }

        switch (codigo) {
          case 200: // Banco añadido correctamente
            console.info(`${codigo} - ${mensaje}`);

            $(responseContainer).addClass("arcopayResponseSuccess");

            responseContainerIcono.innerHTML = `<i class="fa fa-check mr-2"></i>`;
            responseContainerTexto.innerHTML = `${mensaje}. <a href="https://democenter.arcopay.io/agregacionCasos/vodafone/resultado">Ver el resultado procesado.</a>`;

            // setTimeout(() => {
            //   this.$router.push("/agregacionCasos/scoring/resultado");
            // }, 3000);
            break;

          case 400: // Error banco API
          case 402: // Error Widget
            console.error(`${codigo} - ${mensaje}`);

            $(responseContainer).addClass("arcopayResponseError");

            responseContainerIcono.innerHTML = `<i class="fa fa-exclamation-circle mr-2"></i>`;
            responseContainerTexto.innerHTML = mensaje;
            break;

          case 401: // Se necesita más información
            console.warn(`${codigo} - ${mensaje}`);

            $(responseContainer).addClass("arcopayResponseAdvice");

            responseContainerIcono.innerHTML = `<i class="fa fa-bell mr-2"></i>`;
            responseContainerTexto.innerHTML = mensaje;
            break;

          case 403: // Ocultar Toast Respuesta
            console.info(`${codigo} - ${mensaje}`);

            $(responseContainer).removeClass("p-3 mb-4");

            responseContainerIcono.innerHTML = ``;
            responseContainerTexto.innerHTML = ``;
            break;

          case 1000: // Widget cargado correctamente
            console.info(`${codigo} - ${mensaje}`);
            break;

          case 9999: // Cambio en el tamaño del widget
            console.info(`${codigo} - ${mensaje}`);
            widgetContainer.style.height = mensaje + "px";
            this.startScroll();
            document.getElementById("iframeArcopay").contentWindow.postMessage(
              {
                action: "changeIframeHeight",
              },
              "*"
            );
            break;

          case 99999999: // Cambio en el tamaño del iframe
            break;

          default:
            console.warn(`${codigo} - ${mensaje}`);
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.request {
  max-width: 1900px;
}

#flex-content {
  flex: 1 0 auto;
}

h1 {
  color: #ed3022;
}

.btn-primary {
  color: #000;
  background-color: #cfe2ff !important;
  border-color: #0d6efd;
}

/* WIDGET RESPONSE */
.arcopayResponseSuccess {
  background-color: #caffd3;
  color: black;
  border-radius: 0.5rem;
  border: 2px solid #387e38;
}

.arcopayResponseError {
  background-color: #ffcaca;
  color: black;
  border-radius: 0.5rem;
  border: 2px solid #7e3838;
}

.arcopayResponseAdvice {
  background-color: #fff6cf;
  color: black;
  border-radius: 0.5rem;
  border: 2px solid #ffe424;
}
</style>
