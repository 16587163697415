<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-primary px-1 px-md-5">
      <div class="container-fluid">
        <router-link
          class="navbar-brand"
          to="/agregacionCasos/scoring"
          exact-active-class="router-link-exact-active-transparent"
        >
          <img
            id="logo1"
            src="../../../assets/img/mycompany/RedLogo.png"
            alt=""
          />
          <img
            id="logo2"
            src="../../../assets/img/mycompany/MyCompayRed.png"
            alt=""
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/agregacionCasos/scoring/lectura"
                exact-active-class="router-link-exact-active"
                >Lectura</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/agregacionCasos/scoring/resultado"
                exact-active-class="router-link-exact-active"
                >Resultado</router-link
              >
            </li>
          </ul>
          <form class="d-flex d-none d-md-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "ScoringHeader",
  props: {},
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.router-link-exact-active-transparent {
  background-color: transparent;
  color: black !important;
}

.router-link-exact-active {
  background-color: #cfe2ff;
  color: black !important;
}

#logo1 {
  height: 3rem;
}

#logo2 {
  height: 2rem;
}

@media (min-width: 768px) {
  /* >MD */
  #logo1 {
    height: 4rem;
  }

  #logo2 {
    height: 3rem;
  }
}

.bg-primary {
  background-color: #f4f8ff !important;
}
</style>
