<template>
  <div class="noFinanciera">
    <div class="d-flex flex-column justify-content-evenly top-ab">
      <ZonaLinks
        title="Agregación No Financiera"
        :showTitle="false"
        :links="links"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ZonaLinks from "@/components/Home/ZonaLinks.vue";
import linksAgregacionNoFinanciera from "@/api/links/agregacionNoFinanciera.json";

import { mapActions } from "vuex";

export default {
  name: "NoFinanciera",
  components: {
    ZonaLinks,
  },
  data() {
    return {
      links: linksAgregacionNoFinanciera.links,
    };
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Agregación No Financiera",
    });
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-ab {
  padding: 2rem;
}
</style>
