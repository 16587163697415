<template>
  <div class="agregaCasos">
    <div class="d-flex flex-column justify-content-evenly top-ab">
      <ZonaLinks title="Generales" :showTitle="true" :links="linksGenerales" />
      <ZonaLinks title="Clientes" :showTitle="true" :links="linksClientes" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ZonaLinks from "@/components/Home/ZonaLinks.vue";
import linksAgregacionCasos from "@/api/links/agregacionCasos.json";

import { mapActions } from "vuex";

export default {
  name: "AgregaCasos",
  components: {
    ZonaLinks,
  },
  data() {
    return {
      linksGenerales: linksAgregacionCasos.linksGenerales,
      linksClientes: linksAgregacionCasos.linksClientes,
    };
  },
  created() {
    this.setConfigCabecera({
      botonVolver: true,
      textoCabecera: "Casos de uso de Agregación",
    });
  },
  methods: {
    ...mapActions({
      setConfigCabecera: "setConfigCabecera",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.top-ab {
  padding: 2rem;
}
</style>
